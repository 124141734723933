import WelcomeUser from "../components/header/WelcomeUser";
import { KakeiboCards } from "../components/smartcard";
import { KakeiboTrackerChart } from "../data";
import { ComponentForm } from "../containers";
import ComponentTransactions from "../data/tables/ComponentTransactions";
import { useState } from "react";
import { TransactionComponentFilter } from "../interfaces";

function Statistics() {
  const [filter, setFilter] = useState<TransactionComponentFilter>({
    item: "ACCOUNT TRANSFER",
    type: "EXPENSE",
  });

  const handleFilterChange = (name: string, value: string) => {
    setFilter((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="overview-container">
      <div className="overview-header">
        <WelcomeUser />
      </div>
      <div className="insights-panel">
        <KakeiboCards />
      </div>
      <div className="component-graph-panel">
        <div className="data-viewport">
          <ComponentForm onFilterChange={handleFilterChange} />
        </div>
      </div>
      <div className="visualizer-panel">
        <div className="graphs-panel">
          <ComponentTransactions item={filter.item} type={filter.type} />
          <KakeiboTrackerChart />
        </div>
      </div>
    </div>
  );
}

export default Statistics;
