import { AccountData, GenericResponse } from "../interfaces";
import { Tags } from "../store/tags";
import financeApi from "./financeApi";

const accountsApi = financeApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAccount: builder.query<GenericResponse<AccountData[]>, void>({
      query: () => ({
        url: "accounts/active",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<AccountData[]>) => {
        const sortedResponse =
          response.result && Array.isArray(response.result)
            ? response.result.sort((a, b) => {
                if (b.currencyid !== a.currencyid) {
                  return b.currencyid - a.currencyid;
                }
                return b.balance - a.balance;
              })
            : [];

        const modifiedResult = sortedResponse.map((item, index) => ({
          ...item,
          sequentialId: index + 1,
        }));

        return {
          ...response,
          result: modifiedResult,
        };
      },
      providesTags: [Tags.AccountsView],
    }),
  }),
});

export const { useGetAllAccountQuery } = accountsApi;
export default accountsApi;
