import {
  IRecentTransaction,
  GenericResponse,
  ICommonTransaction,
  ITransactionForm,
  ITransaction,
  ITransactionBasic,
  YearMonth,
  ITransactionLite,
  TransactionComponentFilter,
} from "../interfaces";
import { Tags } from "../store/tags";
import financeApi from "./financeApi";

const transactionsApi = financeApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecentTransactions: builder.query<
      GenericResponse<IRecentTransaction[]>,
      void
    >({
      query: () => ({
        url: "transaction/recent",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<IRecentTransaction[]>) => {
        const modifiedResult = response?.result.map((item, index) => ({
          ...item,
          sequentialId: index + 1,
        }));

        return {
          ...response,
          result: modifiedResult,
        };
      },
    }),

    getAccountTransactions: builder.query<
      GenericResponse<ITransaction[]>,
      string
    >({
      query: (accountcode) => ({
        url: "transaction/account",
        method: "GET",
        params: { code: accountcode },
      }),
      transformResponse: (response: GenericResponse<ITransaction[]>) => {
        const modifiedResult = response?.result.map((item, index) => ({
          ...item,
          sequentialId: index + 1,
          amount: item.amount.toString(),
          quantity: item.quantity ?? "",
          exchangerate: item.exchangerate ?? "",
          xtraCharge: item.xtracharge ?? "",
          xtraChargeDesc: item.xtrachargedesc ?? "",
          trnGroupId: item.trngroupid ?? "",
        }));

        return {
          ...response,
          result: modifiedResult,
        };
      },
    }),

    getMonthlyTransactions: builder.query<
      GenericResponse<ITransaction[]>,
      YearMonth
    >({
      query: (yymm) => ({
        url: "transaction/monthly",
        method: "GET",
        params: { year: yymm.year, month: yymm.month },
      }),
    }),

    getIncomeLiteTransactions: builder.query<
      GenericResponse<ITransactionLite[]>,
      YearMonth
    >({
      query: (yymm) => ({
        url: "transaction/incomelite",
        method: "GET",
        params: { year: yymm.year, month: yymm.month },
      }),
    }),

    getCommonTransactions: builder.query<
      GenericResponse<ICommonTransaction[]>,
      void
    >({
      query: () => ({
        url: "ctequery/commontransactions",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<ICommonTransaction[]>) => {
        const modifiedResult = response?.result.map((item, index) => ({
          ...item,
          sequentialId: index + 1,
        }));

        return {
          ...response,
          result: modifiedResult,
        };
      },
    }),

    getTransactionsByComponent: builder.query<
      GenericResponse<ITransactionBasic[]>,
      TransactionComponentFilter
    >({
      query: (filter) => ({
        url: "transaction/component",
        method: "GET",
        params: { item: filter.item, type: filter.type },
      }),
      transformResponse: (response: GenericResponse<ITransactionBasic[]>) => {
        const modifiedResult = response?.result.map((item, index) => ({
          ...item,
          sequentialId: index + 1,
        }));

        return {
          ...response,
          result: modifiedResult,
        };
      },
    }),

    getTransactionTypes: builder.query<string[], void>({
      query: () => ({
        url: "transaction/type",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<string[]>) =>
        response.result,
    }),

    getTransactionModes: builder.query<string[], void>({
      query: () => ({
        url: "transaction/mode",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<string[]>) =>
        response.result,
    }),

    getTransactionGroups: builder.query<string[], void>({
      query: () => ({
        url: "transaction/group",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<string[]>) =>
        response.result,
    }),

    addTransactionRecord: builder.mutation({
      query: (transactionData: ITransactionForm) => ({
        url: "transaction",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: transactionData,
      }),
      invalidatesTags: [Tags.MonthlyReport, Tags.AccountsView],
    }),

    editTransactionRecord: builder.mutation({
      query: (transactionData: ITransaction) => ({
        url: "transaction",
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: transactionData,
      }),
      invalidatesTags: [Tags.MonthlyReport, Tags.AccountsView],
    }),
  }),
});

export const {
  useGetRecentTransactionsQuery,
  useGetAccountTransactionsQuery,
  useGetCommonTransactionsQuery,
  useGetMonthlyTransactionsQuery,
  useGetIncomeLiteTransactionsQuery,
  useGetTransactionTypesQuery,
  useGetTransactionModesQuery,
  useGetTransactionGroupsQuery,
  useGetTransactionsByComponentQuery,

  useAddTransactionRecordMutation,
  useEditTransactionRecordMutation,
} = transactionsApi;
export default transactionsApi;
